<template>
    <div style="padding: 0 20px">
        <div class="big-title-blk">
          <div class="big-title">行业大盘</div>
          <div class="big-file-blk">
              <el-button size="mini" @click="downloadData">下载行业大盘数据模板</el-button>
              <el-button type="primary" size="mini" @click="importData">导入行业大盘数据</el-button>
              <input type="file" name="industry_data" id="industry_data" class="industry_data" ref="industry" @change="importDataEvt">
          </div>
        </div>

        <el-form :model="addForm" ref="addForm" class="demo-ruleForm" :hide-required-asterisk="true">
            <div class="form-item flex">
                <div class="title-item-first"></div>
                <div class="title-right">
                    <div class="title-item align-center">星期一</div>
                    <div class="title-item align-center">星期二</div>
                    <div class="title-item align-center">星期三</div>
                    <div class="title-item align-center">星期四</div>
                    <div class="title-item align-center">星期五</div>
                    <div class="title-item align-center">星期六</div>
                    <div class="title-item align-center">星期日</div>
                </div>
            </div>
            <div class="form-item flex" v-for="(marketItem, marketKey, marketIndex) in addForm.market_data" :key="`market_data_${marketIndex}`">
                <div class="title-item-first">{{marketKey | filterName}}</div>
                <div class="title-right">
                    <el-form-item v-for="(item, index) in marketItem"
                                  :prop="`market_data.${marketKey}.${index}`"
                                  :rules="{required: true, message: '不能为空', trigger: 'blur'}"
                                  class="title-item" :key="`${marketKey}_${index}`">
<!--                        成交转化率(%)-->
                        <el-input-number v-if="marketKey === 'trading_percent_conversion'" :ref="`${marketKey}_${index}`"
                                         class="number-input1" v-model="marketItem[index]" :min="0.01" :max="100" :precision="2" :controls="false" placeholder="两位小数"></el-input-number>
<!--                        成交客单价-->
                        <el-input-number v-else-if="marketKey === 'trading_price'" :ref="`${marketKey}_${index}`"
                                         class="number-input1" v-model="marketItem[index]" :min="0.01" :max="999999999.99" :precision="2" :controls="false" placeholder="两位小数"></el-input-number>
                        <el-input-number v-else :ref="`${marketKey}_${index}`"
                                         class="number-input1" v-model="marketItem[index]" :min="1" :max="999999999" :precision="0" :controls="false" placeholder="正整数"></el-input-number>
                    </el-form-item>
                </div>
            </div>
        </el-form>

        <div class="big-title">
            <span>子行业排行</span>
            <el-button type="primary" @click="addBtn">添加子行业</el-button>
        </div>

        <el-table :data="addForm.sub_industry_data" class="customTable" style="width: 100%;">
            <el-table-column label="子行业名称">
                <template slot-scope="scope">
                    <el-input v-model.trim="scope.row.name"
                              :ref="`industry_name_${scope.$index}`" placeholder="1-20个字" maxlength="20" show-word-limit></el-input>
                </template>
            </el-table-column>
            <el-table-column label="成交金额占比(%)" align="center">
                <template slot-scope="scope">
<!--                    @change="handlingData(scope.row)"-->
                    <el-input-number class="number-input1" v-model="scope.row.trading_money_ratio"
                                     :ref="`industry_trading_money_ratio_${scope.$index}`" :min="0.01" :max="99.98" :precision="2" :controls="false"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="成交金额增幅(%)" align="center">
                <template slot-scope="scope">
                    <el-input-number class="number-input1" v-model="scope.row.trading_money_range"
                                     :ref="`industry_trading_money_range_${scope.$index}`" :min="-999999999.99" :max="999999999.99" :precision="2" :controls="false"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="访客数占比(%)" align="center">
                <template slot-scope="scope">
                    <el-input-number class="number-input1" v-model="scope.row.visitor_number_ratio"
                                     :ref="`industry_visitor_number_ratio_${scope.$index}`" :min="0.01" :max="99.98" :precision="2" :controls="false"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="搜索点击量占比(%)" align="center">
                <template slot-scope="scope">
                    <el-input-number class="number-input1" v-model="scope.row.search_click_ratio"
                                     :ref="`industry_search_click_ratio_${scope.$index}`" :min="0.01" :max="99.98" :precision="2" :controls="false"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="danger" :underline="false" @click="delBtn(scope.row.id, scope.$index)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align: center; margin: 40px 0">
            <el-button @click="goBack">上一步</el-button>
            <el-button type="primary" @click="submitForm('addForm')">下一步</el-button>
        </div>
    </div>
</template>

<script>
    import { dataCentorTplAddIndustryData, dataCentorTplGetIndustryData, downloadIndustryData,importIndustryData } from '@/utils/apis.js'
    export default {
        filters: {
            filterName(val) {
                let obj = {
                    page_view: '浏览量',
                    visitors_index: '访客指数',
                    focus_number: '关注人数',
                    purchase_number: '加购人数',
                    purchase_goods_number: '加购商品件数',
                    search_click_popularity: '搜索点击人气',
                    search_click_index: '搜索点击指数',
                    search_click_rate_index: '搜索点击率指数',
                    trading_money_index: '成交金额指数',
                    trading_orders_index: '成交单量指数',
                    trading_number_index: '成交件数指数',
                    trading_percent_conversion: '成交转化率(%)',
                    trading_price: '成交客单价',
                    industry_stores_number: '行业店铺数',
                    exposure_stores_number: '曝光店铺数',
                    sale_stores_number: '动销店铺数',
                    exposure_goods_number: '曝光商品数',
                }
                return obj[val]
            }
        },
        data() {
            return {
                addForm: {
                    data_center_tpl_id: Number(this.$route.query.data_center_tpl_id) || null,
                    market_data: { // 行业大盘
                        page_view: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 浏览量
                        visitors_index: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 访客指数
                        focus_number: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 关注人数
                        purchase_number: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 加购人数
                        purchase_goods_number: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 加购商品件数
                        search_click_popularity: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 搜索点击人气
                        search_click_index: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 搜索点击指数
                        search_click_rate_index: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 搜索点击率指数
                        trading_money_index: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 成交金额指数
                        trading_orders_index: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 成交单量指数
                        trading_number_index: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 成交件数指数
                        trading_percent_conversion: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 成交转化率(%)
                        trading_price: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 成交客单价
                        industry_stores_number: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 行业店铺数
                        exposure_stores_number: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 曝光店铺数
                        sale_stores_number: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 动销店铺数
                        exposure_goods_number: [void 0, void 0, void 0, void 0, void 0, void 0, void 0], // 曝光商品数
                    },
                    sub_industry_data: [], // 子行业排行
                },
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            downloadData() {
                downloadIndustryData().then(res => {
                    let url = localStorage.getItem('hosturl') + res.data.url;
                    window.open(url, '_blank');
                }).catch(err => {
                  console.log(err);
                });
            },
            importData() {
              this.$refs.industry.click();
            },
            importDataEvt() {
                let formdata = new FormData();
                formdata.append('excel', this.$refs.industry.files[0]);
                importIndustryData(formdata).then(res => {
                    this.addForm.market_data.page_view = res.data[0];
                    this.addForm.market_data.visitors_index = res.data[1];
                    this.addForm.market_data.focus_number = res.data[2];
                    this.addForm.market_data.purchase_number = res.data[3];
                    this.addForm.market_data.purchase_goods_number = res.data[4];
                    this.addForm.market_data.search_click_popularity = res.data[5];
                    this.addForm.market_data.search_click_index = res.data[6];
                    this.addForm.market_data.search_click_rate_index = res.data[7];
                    this.addForm.market_data.trading_money_index = res.data[8];
                    this.addForm.market_data.trading_orders_index = res.data[9];
                    this.addForm.market_data.trading_number_index = res.data[10];
                    this.addForm.market_data.trading_percent_conversion = res.data[11];
                    this.addForm.market_data.trading_price = res.data[12];
                    this.addForm.market_data.industry_stores_number = res.data[13];
                    this.addForm.market_data.exposure_stores_number = res.data[14];
                    this.addForm.market_data.sale_stores_number = res.data[15];
                    this.addForm.market_data.exposure_goods_number = res.data[16];
                }).catch(err => {
                    console.log('err', err);

                })
              this.$refs.industry.value = '';
            },
            getInfo() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id
                }
                dataCentorTplGetIndustryData(params).then((res) => {
                    let arrTmp = res.data.sub_industry_data.map(item => {
                        return {
                            sub_industry_data_id: item.sub_industry_data_id, // 暂时没用
                            name: item.sub_industry_data_name,
                            trading_money_ratio: item.trading_money_ratio,
                            trading_money_range: item.trading_money_range,
                            visitor_number_ratio: item.visitor_number_ratio,
                            search_click_ratio: item.search_click_ratio,
                        }
                    })
                    this.addForm = {
                        data_center_tpl_id: Number(res.data.data_center_tpl_id),
                        market_data: { // 行业大盘
                            page_view: res.data.market_data.page_view,
                            visitors_index: res.data.market_data.visitors_index,
                            focus_number: res.data.market_data.focus_number,
                            purchase_number: res.data.market_data.purchase_number,
                            purchase_goods_number: res.data.market_data.purchase_goods_number,
                            search_click_popularity: res.data.market_data.search_click_popularity,
                            search_click_index: res.data.market_data.search_click_index,
                            search_click_rate_index: res.data.market_data.search_click_rate_index,
                            trading_money_index: res.data.market_data.trading_money_index,
                            trading_orders_index: res.data.market_data.trading_orders_index,
                            trading_number_index: res.data.market_data.trading_number_index,
                            trading_percent_conversion: res.data.market_data.trading_percent_conversion,
                            trading_price: res.data.market_data.trading_price,
                            industry_stores_number: res.data.market_data.industry_stores_number,
                            exposure_stores_number: res.data.market_data.exposure_stores_number,
                            sale_stores_number: res.data.market_data.sale_stores_number,
                            exposure_goods_number: res.data.market_data.exposure_goods_number,
                        },
                        sub_industry_data: arrTmp, // 子行业排行
                        // market_data_id: res.data.market_data.market_data_id // 暂时没用
                    }
                }).catch((err) => {})
            },
            // handlingData(row) {
            //     console.log('row', row)
            //     if (row.trading_money_ratio) {
            //         if (row.visitor_number_ratio) {
            //             this.$set(row, 'search_click_ratio', 100 - row.trading_money_ratio - row.visitor_number_ratio)
            //             console.log('search_click_ratio 111', row.search_click_ratio)
            //             return
            //         }
            //         if (row.search_click_ratio) {
            //             this.$set(row, 'visitor_number_ratio', 100 - row.trading_money_ratio - row.search_click_ratio)
            //             console.log('visitor_number_ratio 111', row.visitor_number_ratio)
            //             return
            //         }
            //     }
            //     if (row.visitor_number_ratio) {
            //         if (row.trading_money_ratio) {
            //             this.$set(row, 'search_click_ratio', 100 - row.visitor_number_ratio - row.trading_money_ratio)
            //             console.log('search_click_ratio 222', row.search_click_ratio)
            //             return
            //         }
            //         if (row.search_click_ratio) {
            //             this.$set(row, 'trading_money_ratio', 100 - row.visitor_number_ratio - row.search_click_ratio)
            //             console.log('trading_money_ratio 222', row.trading_money_ratio)
            //             return
            //         }
            //     }
            //     if (row.search_click_ratio) {
            //         if (row.trading_money_ratio) {
            //             this.$set(row, 'visitor_number_ratio', 100 - row.search_click_ratio - row.trading_money_ratio)
            //             console.log('visitor_number_ratio 333', row.visitor_number_ratio)
            //             return
            //         }
            //         if (row.visitor_number_ratio) {
            //             this.$set(row, 'trading_money_ratio', 100 - row.search_click_ratio - row.visitor_number_ratio)
            //             console.log('trading_money_ratio 333', row.trading_money_ratio)
            //             return
            //         }
            //     }
            // },
            goBack() {
                this.$router.push({
                    path: '/practice/dataStepOne',
                    query: {
                        stepActive: 1,
                        goodClassId: Number(this.$route.query.goodClassId),
                        data_center_tpl_id: Number(this.$route.query.data_center_tpl_id),
                        // status: 'edit',
                    }
                })
            },
            // 添加
            addBtn() {
                let tmp = {
                    name: '',
                    trading_money_ratio: void 0, // 成交金额占比(%)
                    trading_money_range: void 0, // 成交金额增幅(%)
                    visitor_number_ratio: void 0, // 访客数占比(%)
                    search_click_ratio: void 0, // 搜索点击量占比(%)
                }
                this.addForm.sub_industry_data.push(tmp)
            },
            // 删除
            delBtn(id, index) {
                this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box'
                }).then(() => {
                    this.addForm.sub_industry_data.splice(index, 1)
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.addForm.sub_industry_data.length === 0) {
                            return this.$message.warning('请添加子行业数据')
                        }
                        this.addForm.sub_industry_data.forEach((item, index) => {
                            for (const key in item) {
                                if (key !== 'sub_industry_data_id') {
                                    if (!item[key]) {
                                        this.$refs[`industry_${key}_${index}`].$el.classList.add('isError')
                                    } else {
                                        this.$refs[`industry_${key}_${index}`].$el.classList.remove('isError')
                                    }
                                }
                            }
                            if (item.trading_money_ratio && item.visitor_number_ratio && item.search_click_ratio) {
                                if (item.trading_money_ratio + item.visitor_number_ratio + item.search_click_ratio !== 100) {
                                    this.$refs[`industry_trading_money_ratio_${index}`].$el.classList.add('isError')
                                    this.$refs[`industry_visitor_number_ratio_${index}`].$el.classList.add('isError')
                                    this.$refs[`industry_search_click_ratio_${index}`].$el.classList.add('isError')
                                } else {
                                    this.$refs[`industry_trading_money_ratio_${index}`].$el.classList.remove('isError')
                                    this.$refs[`industry_visitor_number_ratio_${index}`].$el.classList.remove('isError')
                                    this.$refs[`industry_search_click_ratio_${index}`].$el.classList.remove('isError')
                                }
                            }
                        })
                        for (let i = 0; i < this.addForm.sub_industry_data.length; i++) {
                            let item = this.addForm.sub_industry_data[i]
                            if (!item.name) {
                                return this.$message.warning('请输入子行业名称')
                            }
                            if (!item.trading_money_ratio) {
                                return this.$message.warning('请输入成交金额占比')
                            }
                            if (!item.trading_money_range) {
                                return this.$message.warning('请输入成交金额增幅')
                            }
                            if (!item.visitor_number_ratio) {
                                return this.$message.warning('请输入访客数占比')
                            }
                            if (!item.search_click_ratio) {
                                return this.$message.warning('请输入搜索点击量占比')
                            }
                            if (item.trading_money_ratio && item.visitor_number_ratio && item.search_click_ratio) {
                                if (item.trading_money_ratio + item.visitor_number_ratio + item.search_click_ratio !== 100) {
                                    return this.$message.warning('成交金额占比、访客数占比、搜索点击量占比加起来等于100.00%')
                                }
                            }
                        }

                        let objTmp = {}
                        for (const key in this.addForm.market_data) {
                            let tmp = {}
                            tmp[`${key}`] = JSON.stringify(this.addForm.market_data[key])
                            objTmp = {...objTmp, ...tmp}
                        }

                        let formData = new FormData()
                        formData.append('data_center_tpl_id', this.addForm.data_center_tpl_id)
                        formData.append('market_data', JSON.stringify(objTmp))
                        formData.append('sub_industry_data', JSON.stringify(this.addForm.sub_industry_data))
                        dataCentorTplAddIndustryData(formData).then((res) => {
                            this.$message.success(res.msg)
                            this.$router.push({
                                path: '/practice/dataStepThree',
                                query: {
                                    stepActive: 3,
                                    goodClassId: Number(this.$route.query.goodClassId),
                                    data_center_tpl_id: Number(this.$route.query.data_center_tpl_id),
                                }
                            })
                        }).catch((err) => {})
                    } else {
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .demo-ruleForm {
        /*margin: 0 20px;*/
        ::v-deep .el-form-item__label {
            font-size: 16px;
            color: #333;
        }
    }
    .big-title-blk {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .industry_data {
      display: none;
    }
    .big-title {
        margin: 40px 0;
        font-size: 16px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        &:before {
            content: '';
            width: 4px;
            height: 16px;
            background: #2DC079;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 2px;
        }
    }
    .form-item {
        font-size: 16px;
        .title-item-first {
            width: 120px;
            height: 40px;
            line-height: 40px;
            text-align: right;
            margin-right: 16px;
        }
        .title-right {
            width: 1%;
            flex: 1;
            display: flex;
        }
        .title-item {
            width: 140px;
            line-height: 40px;
            margin-right: 50px;
        }
    }
    .align-center {
        text-align: center;
    }
    .number-input1 {
        width: 100%;
    }
    ::v-deep .number-input1 {
        &.isError {
            .el-input__inner {
                border-color: #FD4446;
            }
        }
    }
</style>